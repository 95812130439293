import React from "react";

import ContactFrom from "../../components/ContactForm";
import ContentBlock from "../../components/ContentBlock";
import MiddleBlock from "../../components/MiddleBlock";
import Container from "../../common/Container";
import ScrollToTop from "../../common/ScrollToTop";
import DemoBlock from '../../components/DemoBlock'


import Products from "../../content/products.json";
import Intro2 from "../../content/intro2.json";
import SecondBlock from "../../content/secondBlock.json";
import Features from "../../content/features.json";
import Why from "../../content/why.json";
import DiagramsCatalog from "../../content/diagramsCatalog.json";
import ThirdBlock from "../../content/thirdBlock.json";
import FourthBlock from "../../content/fourthBlock.json";
import ContactBlock from "../../content/contactBlock.json";
const Home = () => {
  return (
    <Container>
      <ScrollToTop />

      <MiddleBlock
        title={Intro2.title}
        content={Intro2.text}
        button={Intro2.button}
        video={Intro2.video}
      />

      <ContentBlock
        type="left"
        title={DiagramsCatalog.title}
        content={DiagramsCatalog.text}
        section={DiagramsCatalog.section}
        image={DiagramsCatalog.image}
      />
      
      <ContentBlock
        type="left"
        title={Why.title}
        content={Why.text}
        section={Why.section}
        video={Why.video}
        image={Why.image}
      />
    
      <MiddleBlock
        title={Features.title}
        content={Features.text}
        video={Features.video}
      />

      <ContentBlock
        type="right"
        first="false"
        title={Products.title}
        content={Products.text}
        items={Products.products}
      />
      {/*<ContactFrom title={ContactBlock.title} content={ContactBlock.text} />*/}
    </Container>
  );
};

export default Home;
