import styled from "styled-components";
import VP from '../VideoPlayer';


export const VideoPlayer = styled(VP)`
  margin-top: 1rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
   width: 1120px;
   height: 640px;
  }

`
export const MiddleBlock = styled.section`
  position: relative;
  padding: 5.5rem 0 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const Title = styled.h6``;

export const Content = styled.p`
  padding: 0.75rem 0 3rem;
  max-width: 580px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;

`;

export const ContentWrapper = styled.div`
  max-width: 100%;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;
