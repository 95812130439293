import React, { Fragment } from "react";
import { Row, Col, Select } from "antd";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import Container from "../../common/Container";
import SvgIcon from "../../common/SvgIcon";

import * as S from "./styles";

const Footer = () => {
  const { Option } = Select;
  const { t } = useTranslation();
  const handleChange = (event) => {
    i18n.changeLanguage(event);
  };

  return (
    <Fragment>
        <S.Footer>
          <Container>
            <Row type="flex" justify="space-between">
              <Col lg={10} md={10} sm={12} xs={24}>
                <S.Language>{t("Contact")}</S.Language>
                <S.LargeLink href="https://contact.diagram.codes">{t("Want to contact us?")}</S.LargeLink>
                <S.Para>
                  {t(
                    `Questions about Diagram.codes? Feel free to reach out.`
                  )}
                </S.Para>
                
              </Col>
              <Col lg={8} md={8} sm={12} xs={24}>
                 <S.Title>{t("Links")}</S.Title>
                <S.LargeLink left="true" href="https://blog.diagram.codes">
                  {t("Blog")}
                </S.LargeLink>
                <S.LargeLink left="true" href="https://cloud.diagram.codes">
                  {t("Cloud Platform")}
                </S.LargeLink>
                <S.LargeLink left="true" href="https://studio.diagram.codes">
                  {t("Desktop (Diagram Codes Studio")}
                </S.LargeLink>
                <S.LargeLink left="true" href="https://playground.diagram.codes">
                  {t("Playground")}
                </S.LargeLink>
              </Col>
              <Col lg={6} md={6} sm={12} xs={24}>
                
              </Col>
            </Row>
            
          </Container>
        </S.Footer>
        <S.Extra>
          <Container border="true">
            
            <Row type="flex" justify="space-between">
              <Col lg={12} md={12} sm={12} xs={24}>
                <S.CSite>
                  All Rights Reserved - <a href="https://volarelabs.co">Volare Labs SAS / Volare Labs Inc. </a> © {new Date().getFullYear()}
                </S.CSite>
              </Col>
            </Row>
          </Container>
        </S.Extra>
    </Fragment>
  );
};

export default Footer;
