import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
// Can also be included with a regular script tag
import Typed from 'typed.js';

import key1 from '../../content/demos/sequence/1.svg'
import key2 from '../../content/demos/sequence/2.svg'
import key3 from '../../content/demos/sequence/3.svg'
import key4 from '../../content/demos/sequence/4.svg'
import key5 from '../../content/demos/sequence/5.svg'
import key6 from '../../content/demos/sequence/6.svg'
import key7 from '../../content/demos/sequence/7.svg'
import key8 from '../../content/demos/sequence/8.svg'
import key9 from '../../content/demos/sequence/9.svg'

const keyframes = [key1, key2, key3, key4, key5, key6, key7, key8, key9]
const DemoText = styled.div`
    font-size: 1.5rem;
    width: 50%;
`

const sequence = `
customer -> atm: "insert card"
atm --> customer: "ask for pin"
customer -> atm: "enter pin number"
atm --> customer: "validation message"
customer -> atm: "enter amount"
customer -> atm: "click window"
atm -> service: "withdraw(amount)"
service -> bank: "validate transaction"
bank --> service: "ok"
service --> atm: "ok"
atm --> customer: "Inform User"
`

/*@media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
*/
const DemoContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`

const ImageContainer = styled.div`
    position: relative;
    width: 400px;
    height: 200px;
`

const KeyframeImage = styled.img`
    opacity: ${(props) => props.visible ? 1 : 0};
    position: absolute;
    top:0;
    left: 0;
    object-fit: contain;
`

export default () => {
    const [currentKeyframe, setCurrentKeyframe] = useState(0)
    const [previousLine, setPreviousLine] = useState('');
    const [history, setHistory] = useState([]);
    const strings = sequence.split('\n');

    const cbstringTyped = (pos, self) => {
            setCurrentKeyframe(pos-1);
          }

    useEffect(()=>{
        var options = {
          // strings: [sequence.replace(/(?:\r\n|\r|\n)/g,'<br>')],
          strings: sequence.split('\n'),
          typeSpeed: 40,
          loop: true,
          fadeOut: true,
          onStringTyped: cbstringTyped
        };
        var typed = new Typed('.demotext', options);
    }, [])

    return <DemoContainer>
        <div>
            {history.map((item, ix) => <div key={ix}> {item}</div>)}
            <DemoText className="demotext"></DemoText>
        </div>
        <ImageContainer>
            {keyframes.map((key, ix) => {
                return <KeyframeImage src={key} key={key} visible={currentKeyframe ===  ix} />
            })
            }
            
        </ImageContainer>
     </DemoContainer>
}